// src/components/SearchComponent.js
import React, { useState } from 'react';
import SearchForm from './SearchForm';
import LoadingBar from './LoadingBar';
import ResultsGrid from './ResultsGrid';
import { callOpenAI } from '../services/openai';

function SearchComponent() {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setResults([]);  // Clear results when input is cleared
      setLoading(false);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Insert actual API call logic here
    try {
      const response = await callOpenAI(searchTerm);
      setResults(response);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setResults([...Array(9).keys()].map(i => ({ content: `Item ${i + 1}` })));
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setResults([]);
    setLoading(false);
  };

  return (
    <div>
      <SearchForm
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onSearchSubmit={handleSearchSubmit}
        onClearSearch={handleClearSearch}
      />
      {loading && <LoadingBar />}
      {!loading && results.length > 0 && <ResultsGrid results={results} />}
    </div>
  );
}

export default SearchComponent;
