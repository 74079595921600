// src/services/openai.js
import axios from 'axios';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const callOpenAI = async (query) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo', // Choose the appropriate model
        messages: [
            {"role": "system", "content": "You are a health advisor. Given the user query, suggest a 30 days fitness plan with gym and at home exercies. mark each with 'gym' or 'at home' in the following format:. {day:day number}:{gym/at home}:{Exercise}. Just the result, nothing else "},
            {"role": "user", "content": query}
          ],
        max_tokens: 500, // Adjust as needed
        temperature: 0.7, // Adjust as needed
        n: 1,
        // stop: '\n'
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`
        }
      }
    );

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    throw error;
  }
};
