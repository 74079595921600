// src/components/LoadingBar.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const barGrow = keyframes`
  0% {
    width: 0;
    opacity: 1;
  }
  50% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
`;

const LoadingBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Bar = styled.div`
  background-color: ${props => props.color};
  height: 4px;
  width: 100%;
  margin: 2px 0;
  animation: ${barGrow} 2s ${props => props.delay}s infinite;
`;

const LoadingBar = () => (
  <LoadingBarContainer>
    <Bar color="#6495ED" delay={0} />
    <Bar color="#6B8E23" delay={0.3} />
    <Bar color="#FFA500" delay={0.6} />
  </LoadingBarContainer>
);

export default LoadingBar;
