// src/components/ResultsGrid.js
import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 90%;
  margin: 40px auto;
`;

const Card = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  padding: 20px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const DayNumber = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
`;

const LocationTag = styled.span`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: ${(props) => (props.isAtHome ? '#FF5722' : '#3F51B5')};
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
`;

const ImagePlaceholder = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin: 0 auto;
  margin-bottom: 15px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #0056b3;
  }
`;

const parseResult = (result) => {
    const lines = result.split('\n');
    const parsedArray = [];
  
    lines.forEach((line) => {
      // Remove curly braces and split the line into components
      const components = line.replace(/{|}/g, '').split(': ');
  
      if (components.length === 3) {
        const day = components[0].trim().substring(4); // Extract day number
        const location = components[1].trim(); // Extract location
        const exercise = components[2].trim(); // Extract exercise description
        parsedArray.push({ day, location, exercise });
      }
    });
  
    return parsedArray;
  };

  const ResultsGrid = ({ results }) => (
    <Grid>
      {parseResult(results).map((item, index) => (
        <Card key={index}>
          <DayNumber>{`Day ${item.day}`}</DayNumber>
          <LocationTag isAtHome={item.location === 'at home'}>
            {item.location}
          </LocationTag>
          <ImagePlaceholder />
          <Description>{item.exercise}</Description>
          <Button>View More</Button>
        </Card>
      ))}
    </Grid>
  );
  

export default ResultsGrid;
