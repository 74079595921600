// src/components/SearchForm.js
import React from 'react';
import styled from 'styled-components';

// Using icons from react-icons for simplicity
import { AiOutlineSearch, AiOutlineClose } from 'react-icons/ai';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  padding: 20px;
`;

const SearchBoxContainer = styled.div`
  position: relative;
  width: 50%;
  max-width: 600px;
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 10px 40px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 16px;
`;

const Icon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #b0b0b0;
  font-size: 20px;
  cursor: pointer;
`;

const SearchIcon = styled(Icon)`
  left: 15px;
`;

const ClearIcon = styled(Icon)`
  right: 15px;
`;

const SearchForm = ({ searchTerm, onSearchChange, onSearchSubmit, onClearSearch }) => (
  <SearchContainer>
    <SearchBoxContainer>
      <form onSubmit={onSearchSubmit}>
        <SearchBox
          type="text"
          placeholder="Type to search..."
          value={searchTerm}
          onChange={onSearchChange}
        />
        <SearchIcon><AiOutlineSearch /></SearchIcon>
        {searchTerm && <ClearIcon onClick={onClearSearch}><AiOutlineClose /></ClearIcon>}
      </form>
    </SearchBoxContainer>
  </SearchContainer>
);

export default SearchForm;
